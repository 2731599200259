/* Action Man Bold */
@font-face {
  font-family: "Action Man";
  src: url("assets/fonts/Action_Man_Bold-webfont.woff") format("woff"),
    url("assets/fonts/Action_Man_Bold-webfont.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

/* Action Man Bold Italic */
@font-face {
  font-family: "Action Man";
  src: url("assets/fonts/Action_Man_Bold_Italic-webfont.woff") format("woff"),
    url("assets/fonts/Action_Man_Bold_Italic-webfont.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

/* Action Man Extended */
@font-face {
  font-family: "Action Man Extended";
  src: url("assets/fonts/Action_Man_Extended-webfont.woff") format("woff"),
    url("assets/fonts/Action_Man_Extended-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Action Man Extended Bold */
@font-face {
  font-family: "Action Man Extended";
  src: url("assets/fonts/Action_Man_Extended_Bold-webfont.woff") format("woff"),
    url("assets/fonts/Action_Man_Extended_Bold-webfont.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

/* Action Man Extended Bold Italic */
@font-face {
  font-family: "Action Man Extended";
  src: url("assets/fonts/Action_Man_Extended_Bold_Italic-webfont.woff")
      format("woff"),
    url("assets/fonts/Action_Man_Extended_Bold_Italic-webfont.ttf")
      format("truetype");
  font-weight: bold;
  font-style: italic;
}

/* Action Man Extended Italic */
@font-face {
  font-family: "Action Man Extended";
  src: url("assets/fonts/Action_Man_Extended_Italic-webfont.woff")
      format("woff"),
    url("assets/fonts/Action_Man_Extended_Italic-webfont.ttf")
      format("truetype");
  font-weight: normal;
  font-style: italic;
}

/* Action Man Italic */
@font-face {
  font-family: "Action Man";
  src: url("assets/fonts/Action_Man_Italic-webfont.woff") format("woff"),
    url("assets/fonts/Action_Man_Italic-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

/* Action Man */
@font-face {
  font-family: "Action Man";
  src: url("assets/fonts/Action_Man-webfont.woff") format("woff"),
    url("assets/fonts/Action_Man-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Action Man Shaded */
@font-face {
  font-family: "Action Man Shaded";
  src: url("assets/fonts/Action_Man_Shaded-webfont.woff") format("woff"),
    url("assets/fonts/Action_Man_Shaded-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Action Man Shaded Italic */
@font-face {
  font-family: "Action Man Shaded";
  src: url("assets/fonts/Action_Man_Shaded_Italic-webfont.woff") format("woff"),
    url("assets/fonts/Action_Man_Shaded_Italic-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

/* Font Family Usage */
body {
  font-family: "Action Man", sans-serif;
}

.alignInputsRight input.native-input {
  text-align: right;
  padding-right: 3px;
}

/* Set the color on all ion-button elements */
ion-modal {
  --max-width: 1000px;
}

@media only screen and (min-width: 768px) and (min-height: 768px) {
  ion-modal {
    --width: 1000px;
  }
}
